import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) { }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
            new AppMenuItem('Dashboard', 'Pages.Tenants.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            //new AppMenuItem('PhoneBook', 'Pages.Tenants.PhoneBook', 'flaticon-book', '/app/main/phonebook'),
            new AppMenuItem('Leads', 'Pages.Tenants.Lead', 'flaticon2-group', '', [],
                [
                    new AppMenuItem('Lead', 'Pages.Tenants.Lead', 'flaticon2-group', '/app/main/lead'),
                    //new AppMenuItem('Lead Atividade', 'Pages.Tenants.Atividade', 'flaticon2-group', '/app/main/leadAtividade'),
                    //new AppMenuItem('Lead Estágio', 'Pages.Tenants.Estagio', 'flaticon2-group', '/app/main/leadEstagio'),
                    //new AppMenuItem('Lead Origem', 'Pages.Tenants.Origem', 'flaticon2-group', '/app/main/leadOrigem'),
                    //new AppMenuItem('Lead Prioridade', 'Pages.Tenants.Prioridade', 'flaticon2-group', '/app/main/leadPrioridade'),
                    //new AppMenuItem('Lead Tipo', 'Pages.Tenants.Tipo', 'flaticon2-group', '/app/main/leadTipo'),
                ]
            ),
            //new AppMenuItem('Atendimento', 'Pages.Tenants.Lead', 'flaticon-home', '', [],
            //    [
                    //new AppMenuItem('Atender', 'Pages.Administration.Portal', 'flaticon-map', '/app/main/atendimento'),
                    //new AppMenuItem('Chegada Corretor', 'Pages.Tenants.PortalConfig', 'flaticon-map', '/app/main/portal/config/portal-config'),
                    //new AppMenuItem('Repique Eletrônico', 'Pages.Tenants.PortalConfig', 'flaticon-map', '/app/main/portal/config/portal-config'),
                    //new AppMenuItem('Configuração', 'Pages.Tenants.PortalConfig', 'flaticon-map', '/app/main/portal/config/portal-config'),
                    //new AppMenuItem('Lista Negra', 'Pages.Tenants.PortalConfig', 'flaticon-map', '/app/main/portal/config/portal-config'),
                    //new AppMenuItem('Faltas', 'Pages.Tenants.PortalConfig', 'flaticon-map', '/app/main/portal/config/portal-config'),
                    //new AppMenuItem('Visitas', 'Pages.Tenants.Visitacao', 'flaticon-map', '/app/main/portal/config/portal-config'),
            //    ]
            //),
            new AppMenuItem('Imóvel', 'Pages.Tenants.Imovel', 'flaticon-home', '', [],
                [
                    new AppMenuItem('Consultar', 'Pages.Tenants.Imovel', 'flaticon-map', '/app/main/imovel'),
                    //new AppMenuItem('Laudo Técnico', 'Pages.Tenants.Laudo', 'flaticon-map', '/app/main/imovel-laudo'),
                    new AppMenuItem('Destaques', 'Pages.Tenants.Imovel', 'flaticon-map', '/app/main/imovel-destaque'),
                    //new AppMenuItem('Classificação', 'Pages.Tenants.ClassiImo', 'flaticon-suitcase', '/app/main/tabelas/imovel-classificacao'),
                    new AppMenuItem('Proprietários', 'Pages.Tenants.Entidade', 'flaticon-users', '/app/main/entidade'),
                ]
            ),
            /* new AppMenuItem('Corretagem', 'Pages.Tenants.Visitacao', 'flaticon-home', '', [],
                [
                    new AppMenuItem('Atendimento', 'Pages.Administration.Portal', 'flaticon-map', '/app/main/corretor'),
                    new AppMenuItem('Consultar', 'Pages.Tenants.PortalConfig', 'flaticon-map', '/app/main/portal/config/portal-config'),
                    new AppMenuItem('Classificar', 'Pages.Tenants.ClassiCor', 'flaticon-map', '/app/main/portal/config/portal-config'),
                    new AppMenuItem('Visitação', 'Pages.Tenants.Visitacao', 'flaticon-map', '/app/main/portal/config/portal-config'),
                ]
            ), */
            /* new AppMenuItem('Gerência', 'Pages.Tenants.ClassiRespTec', 'flaticon-home', '', [],
                [
                    new AppMenuItem('Classificação Imóvel', 'Pages.Tenants.Proposta', 'flaticon-map', '/app/main/proposta'),
                    new AppMenuItem('Classificação Corretor', 'Pages.Tenants.PropostaTP', 'flaticon-book', '/app/main/proposta'),
                    new AppMenuItem('Faixa de Preço', 'Pages.Tenants.PropostaTP', 'flaticon-book', '/app/main/proposta'),
                    new AppMenuItem('Responsável Técnico', 'Pages.Tenants.ClassiRespTec', 'flaticon-book', '/app/main/proposta'),
                    new AppMenuItem('Controle de Visitas', 'Pages.Tenants.Visitacao', 'flaticon-map', '/app/main/portal/config/portal-config'),
                ]
            ),
            new AppMenuItem('Controle de Proposta', 'Pages.Tenants.Proposta', 'flaticon-home', '', [],
                [
                    new AppMenuItem('Proposta', 'Pages.Tenants.Proposta', 'flaticon-map', '/app/main/proposta'),
                    new AppMenuItem('Tipo de Proposta', 'Pages.Tenants.PropostaTP', 'flaticon-book', '/app/main/proposta-tipo'),
                    new AppMenuItem('Modelo de Proposta', 'Pages.Tenants.PropostaModelo', 'flaticon-book', '/app/main/proposta-modelo'),
                ]
            ),
            new AppMenuItem('Vendas', 'Pages.Tenants.Proposta', 'flaticon-home', '', [],
                [
                    new AppMenuItem('Venda', 'Pages.Tenants.Proposta', 'flaticon-map', '/app/main/proposta'),
                    new AppMenuItem('Tipo de Autorização', 'Pages.Tenants.PropostaTP', 'flaticon-book', '/app/main/proposta'),
                    new AppMenuItem('DIMOB', 'Pages.Tenants.Lancamento', 'flaticon-users', '/app/main/proposta'),
                ]
            ),
            new AppMenuItem('Documentação', 'Pages.Tenants.Proposta', 'flaticon-home', '', [],
                [
                    new AppMenuItem('Venda', 'Pages.Tenants.Proposta', 'flaticon-map', '/app/main/proposta'),
                    new AppMenuItem('Tipo de Autorização', 'Pages.Tenants.PropostaTP', 'flaticon-book', '/app/main/proposta'),
                ]
            ),
            new AppMenuItem('Financeiro', 'Pages.Tenants.Lancamento', 'flaticon-interface-8', '', [],
                [
                    new AppMenuItem('Lançamentos', 'Pages.Tenants.Lancamento', 'flaticon-users', '/app/main/lancamento'),
                    new AppMenuItem('CentroDeCusto', 'Pages.Tenants.CentroCusto', 'flaticon-map', '/app/main/centro-custo'),
                    new AppMenuItem('Plano de Contas', 'Pages.Tenants.PlanoConta', 'flaticon-users', '/app/main/plano-conta'),
                    new AppMenuItem('Sub Plano de Contas', 'Pages.Tenants.PlanoSubConta', 'flaticon-users', '/app/main/plano-sub-conta'),
                    new AppMenuItem('Conta', 'Pages.Tenants.Conta', 'flaticon-suitcase', '/app/main/caixa-conta'),
                ]
            ), */
            new AppMenuItem('Gerenciamento Site',
                null,
                'flaticon-interface-8',
                '',
                [],
                [
                    new AppMenuItem('Institucional', 'Pages.Tenants.SiteInstitucional', 'flaticon-mail-1', '/app/main/site-institucional'),
                    new AppMenuItem(
                        'Links',
                        'Pages.Tenants.SiteLinks',
                        'flaticon-mail-1',
                        '/app/main/site-links'
                    ),
                    new AppMenuItem(
                        'SEO',
                        'Pages.Tenants.SiteSEO',
                        'flaticon-mail-1',
                        '/app/main/site-seo'
                    ),
                    new AppMenuItem('Blog', 'Pages.Tenants.Blog', 'flaticon-book', '/app/main/blog'),
                ]
            ),
            new AppMenuItem('Portal Imobiliário', 'Pages.Administration.Portal', 'flaticon-home', '', [],
                [
                    new AppMenuItem('Portal', 'Pages.Administration.Portal', 'flaticon-map', '/app/main/portal'),
                    //new AppMenuItem('Configuração', 'Pages.Tenants.PortalConfig', 'flaticon-map', '/app/main/portal/config/portal-config'),
                ]
            ),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem('Administration',
                '',
                'flaticon-interface-8',
                '',
                [],
                [
                    new AppMenuItem(
                        'OrganizationUnits',
                        'Pages.Administration.OrganizationUnits',
                        'flaticon-map',
                        '/app/admin/organization-units'
                    ),
                    new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                    new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                    new AppMenuItem(
                        'Languages',
                        'Pages.Administration.Languages',
                        'flaticon-tabs',
                        '/app/admin/languages',
                        ['/app/admin/languages/{name}/texts']
                    ),
                    new AppMenuItem(
                        'AuditLogs',
                        'Pages.Administration.AuditLogs',
                        'flaticon-folder-1',
                        '/app/admin/auditLogs'
                    ),
                    new AppMenuItem(
                        'Maintenance',
                        'Pages.Administration.Host.Maintenance',
                        'flaticon-lock',
                        '/app/admin/maintenance'
                    ),
                    new AppMenuItem(
                        'Subscription',
                        'Pages.Administration.Tenant.SubscriptionManagement',
                        'flaticon-refresh',
                        '/app/admin/subscription-management'
                    ),
                    new AppMenuItem(
                        'VisualSettings',
                        'Pages.Administration.UiCustomization',
                        'flaticon-medical',
                        '/app/admin/ui-customization'
                    ),
                    new AppMenuItem(
                        'WebhookSubscriptions',
                        'Pages.Administration.WebhookSubscription',
                        'flaticon2-world',
                        '/app/admin/webhook-subscriptions'
                    ),
                    new AppMenuItem(
                        'DynamicProperties',
                        'Pages.Administration.DynamicProperties',
                        'flaticon-interface-8',
                        '/app/admin/dynamic-property'
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Host.Settings',
                        'flaticon-settings',
                        '/app/admin/hostSettings'
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Tenant.Settings',
                        'flaticon-settings',
                        '/app/admin/tenantSettings'
                    ),
                    new AppMenuItem(
                        'Notifications',
                        '',
                        'flaticon-alarm',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'Inbox',
                                '',
                                'flaticon-mail-1',
                                '/app/notifications'
                            ),
                            new AppMenuItem(
                                'MassNotifications',
                                'Pages.Administration.MassNotification',
                                'flaticon-paper-plane',
                                '/app/admin/mass-notifications'
                            )
                        ]
                    )
                ]
            ),
            new AppMenuItem('Configurações',
                null,
                'flaticon-interface-8',
                '',
                [],
                [
                    new AppMenuItem(
                        'Site',
                        null,
                        'flaticon-alarm',
                        '',
                        [],
                        [
                            //new AppMenuItem('Institucional', 'Pages.Tenants.SiteInstitucional', 'flaticon-mail-1', '/app/main/site-institucional'),
                            new AppMenuItem(
                                'Links',
                                'Pages.Tenants.SiteLinks',
                                'flaticon-mail-1',
                                '/app/main/site-links'
                            ),
                            /* new AppMenuItem(
                                'SEO',
                                'Pages.Tenants.SiteSEO',
                                'flaticon-mail-1',
                                '/app/main/site-seo'
                            ), */
                        ]
                    ),
                    /* new AppMenuItem(
                        'Pagamentos',
                        'Pages.Tenants.CobrancaInt',
                        'flaticon-credit-card',
                        '/app/admin/languages',
                        ['/app/admin/languages/{name}/texts']
                    ), */
                    new AppMenuItem('Tabelas',
                        null,
                        'flaticon-interface-8',
                        '',
                        [],
                        [
                            new AppMenuItem('Tipo', 'Pages.Tenants.ImovelTipo', 'flaticon-home', '/app/main/imovelTipo'),
                            new AppMenuItem('Sub Tipo', 'Pages.Tenants.ImovelSubTipo', 'flaticon-home', '/app/main/imovelSubTipo'),
                            new AppMenuItem('Agente Financeiro', 'Pages.Tenants.ImovelAgenteFinanceiro', 'flaticon-home', '/app/main/imovelAgenteFinanceiro'),
                            new AppMenuItem('Autorização', 'Pages.Tenants.ImovelAutorizacao', 'flaticon-home', '/app/main/imovelAutorizacao'),
                            new AppMenuItem('Autorização Venda', 'Pages.Tenants.ImovelAutorizacaoVenda', 'flaticon-home', '/app/main/imovelAutorizacaoVenda'),
                            new AppMenuItem('Documentação', 'Pages.Tenants.ImovelDocumentacao', 'flaticon-home', '/app/main/imovelDocumentacao'),
                            new AppMenuItem('Motivo', 'Pages.Tenants.ImovelMotivo', 'flaticon-home', '/app/main/imovelMotivo'),
                            new AppMenuItem('Ocupante', 'Pages.Tenants.ImovelOcupante', 'flaticon-home', '/app/main/imovelOcupante'),
                            new AppMenuItem('Situação', 'Pages.Tenants.ImovelSituacao', 'flaticon-home', '/app/main/imovelSituacao'),
                            new AppMenuItem('Estado', 'Pages.Tenants.ImovelEstado', 'flaticon-home', '/app/main/imovelEstado'),
                            new AppMenuItem('Fachada', 'Pages.Tenants.ImovelFachada', 'flaticon-home', '/app/main/imovelFachada'),
                            new AppMenuItem('Vista', 'Pages.Tenants.ImovelVista', 'flaticon-home', '/app/main/imovelVista'),
                            new AppMenuItem('Classificação', 'Pages.Tenants.ImovelClassificacao', 'flaticon-home', '/app/main/imovelClassificacao'),
                        ]
                    ),
                ]
            ),
            //new AppMenuItem('Relatórios', null, 'flaticon-shapes', '/app/admin/demo-ui-components'),
            new AppMenuItem('Suporte', null, 'flaticon-shapes', '/app/main/suporte'),
            //new AppMenuItem('Ajuda', null, 'flaticon-shapes', '/app/main/ajuda'),
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}

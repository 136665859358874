import { EntidadeServiceProxy } from '@shared/service-proxies/service-proxies';
import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.DynamicPropertyServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyServiceProxy,
        ApiServiceProxies.DynamicPropertyValueServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
        ApiServiceProxies.TwitterServiceProxy,
        ApiServiceProxies.PersonServiceProxy,
        ApiServiceProxies.ImovelServiceProxy,
        ApiServiceProxies.ImovelTipoServiceProxy,
        ApiServiceProxies.ImovelEstadoServiceProxy,
        ApiServiceProxies.ImovelAgenteFinanceiroServiceProxy,
        ApiServiceProxies.ImovelAutorizacaoServiceProxy,
        ApiServiceProxies.ImovelAutorizacaoVendaServiceProxy,
        ApiServiceProxies.ImovelDescricaoServiceProxy,
        ApiServiceProxies.ImovelDocumentacaoServiceProxy,
        ApiServiceProxies.ImovelFachadaServiceProxy,
        ApiServiceProxies.ImovelMotivoServiceProxy,
        ApiServiceProxies.ImovelOcupanteServiceProxy,
        ApiServiceProxies.SituacaoImovelServiceProxy,
        ApiServiceProxies.ImovelSubTipoServiceProxy,
        ApiServiceProxies.LeadServiceProxy,
        ApiServiceProxies.LeadAtividadeServiceProxy,
        ApiServiceProxies.LeadEstagioServiceProxy,
        ApiServiceProxies.LeadOrigemServiceProxy,
        ApiServiceProxies.LeadPrioridadeServiceProxy,
        ApiServiceProxies.LeadTipoServiceProxy,
        ApiServiceProxies.FotoServiceProxy,
        ApiServiceProxies.MarcaDaguaFotoImovelServiceProxy,
        ApiServiceProxies.LeadStatusServiceProxy,
        ApiServiceProxies.FacebookServiceProxy,
        ApiServiceProxies.PortalServiceProxy,
        ApiServiceProxies.PortalConfigServiceProxy,
        ApiServiceProxies.BlogServiceProxy,
        ApiServiceProxies.SeoServiceProxy,
        ApiServiceProxies.SiteInstitucionalServiceProxy,
        ApiServiceProxies.SiteAvaliacaoServiceProxy,
        ApiServiceProxies.SiteParceiroServiceProxy,
        ApiServiceProxies.SiteLinkUtilServiceProxy,
        ApiServiceProxies.SiteTestemunhoServiceProxy,
        ApiServiceProxies.ParametroServiceProxy,
        ApiServiceProxies.PropostaServiceProxy,
        ApiServiceProxies.PropostaTPServiceProxy,
        ApiServiceProxies.BancoServiceProxy,
        ApiServiceProxies.ContaBancoServiceProxy,
        ApiServiceProxies.LancamentoServiceProxy,
        ApiServiceProxies.CentroCustoServiceProxy,
        ApiServiceProxies.PlanoContaServiceProxy,
        ApiServiceProxies.PlanoSubContaServiceProxy,
        ApiServiceProxies.CobrancaTPServiceProxy,
        ApiServiceProxies.FormaPgtoServiceProxy,
        ApiServiceProxies.CaixaContaServiceProxy,
        ApiServiceProxies.MoedaServiceProxy,
        ApiServiceProxies.VendaTPServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.EmailMarketingServiceProxy,
        ApiServiceProxies.EmailMarketingTPServiceProxy,
        ApiServiceProxies.PostModeloWebServiceProxy,
        ApiServiceProxies.PostagemServiceProxy,
        ApiServiceProxies.ClassiCorServiceProxy,
        ApiServiceProxies.ClassiImoServiceProxy,
        ApiServiceProxies.ClassiCorTPServiceProxy,
        ApiServiceProxies.ClassiFaixaImoTPServiceProxy,
        ApiServiceProxies.ClassiFaixaPrcCorServiceProxy,
        ApiServiceProxies.ClassiFaixaPrcImoServiceProxy,
        ApiServiceProxies.ClassiRespTecServiceProxy,
        ApiServiceProxies.CobrancaIntServiceProxy,
        ApiServiceProxies.NewsletterServiceProxy,
        ApiServiceProxies.EntidadeServiceProxy,
        ApiServiceProxies.RelEntidadeServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule {}
